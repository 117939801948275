import { isOnBrowser } from 'services/browser';

export const getQueryParams = (): URLSearchParams | undefined => {
  if (!isOnBrowser()) {
    return undefined;
  }
  const href = window.location.href;
  const url = new URL(href);
  return new URLSearchParams(url.search);
};

export const getShortenedName = ({
  firstName,
  lastName,
}: {
  firstName: string;
  lastName: string;
}) => {
  if (lastName.length > 0) {
    return `${firstName} ${lastName.charAt(0)}.`;
  } else {
    return firstName;
  }
};
