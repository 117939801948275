import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import { HeaderVariant } from '../Header';
import { HOME } from '../../../constants/routes';

const STYLES = {
  light: {
    '--color': 'var(--color-primary-500)',
  },
  dark: {
    '--color': 'var(--color-white)',
  },
};

interface Props {
  variant: HeaderVariant;
}

const Logo = ({ variant }: Props) => {
  const styles = STYLES[variant];

  return (
    <Link href={HOME} passHref>
      <Wrapper>
        <Header style={styles}>solin</Header>
      </Wrapper>
    </Link>
  );
};

const Wrapper = styled.a`
  text-decoration: none;
`;

const Header = styled.h1`
  text-decoration: none;
  font-size: calc(36 / 16 * 1rem);
  font-family: 'Raleway';
  font-weight: var(--font-weight-semibold);
  color: var(--color);
  line-height: 1;
`;

export default Logo;
