import React, { useEffect } from 'react';
import styled from 'styled-components';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import Icon, { IconNames } from '../../Icons';
import UnstyledButton from '../../UnstyledButton';
import NavigationItem from './NavigationItem';
import { LOGIN, HOME, PROFILE, SETTINGS } from '../../../constants/routes';
import { HeaderVariant } from '../Header';
import {
  SubscriptionResponse,
  FetchUserProfileResponse,
} from '@solin-fitness/types';
import { useRouter } from 'next/router';
import useLogoutUser from 'hooks/useLogoutUser';
import { useQueryClient } from 'react-query';

const DefaultMobileMenu = ({ variant }: { variant: HeaderVariant }) => (
  <Nav>
    <NavigationItem
      href={LOGIN}
      variant={variant}
      value="Login"
      iconRight
      id={IconNames.chevronRight}
    />
    <NavigationItem
      href={HOME}
      variant={variant}
      value="Home"
      iconRight
      id={IconNames.chevronRight}
    />
  </Nav>
);

const SignedInMobileMenu = ({
  variant,
  subscriptions,
  profile,
  onLogout,
}: {
  variant: HeaderVariant;
  subscriptions?: SubscriptionResponse[];
  profile: FetchUserProfileResponse;
  onLogout: () => void;
}) => {
  const userInitials = `${profile.firstName?.[0]}${profile.lastName?.[0]}`;
  return (
    <Nav>
      <NavigationItem
        href={PROFILE}
        variant={variant}
        value={profile.firstName}
        avatarLeft
        avatar={profile.profilePictureUrlPublicId}
        initials={userInitials}
        iconRight
        id={IconNames.chevronRight}
      />
      {subscriptions?.map((subscription) => (
        <NavigationItem
          key={subscription.pageUrl}
          href={{
            pathname: '/[creatorPage]',
            query: {
              creatorPage: subscription.pageUrl,
            },
          }}
          variant={variant}
          avatarLeft
          value={subscription.pageName}
          avatar={subscription.creator.profile.profilePictureUrlPublicId}
          iconRight
          id={IconNames.chevronRight}
        />
      ))}
      <NavigationItem
        href={SETTINGS}
        variant={variant}
        value="Settings"
        iconRight
        id={IconNames.chevronRight}
      />
      <NavigationItem
        variant={HeaderVariant.dark}
        value="Log Out"
        iconRight
        id={IconNames.chevronRight}
        onClick={onLogout}
      />
    </Nav>
  );
};

interface Props {
  isOpen: boolean;
  onDismiss: () => void;
  signedIn: boolean;
  subscriptions?: SubscriptionResponse[];
  profile?: FetchUserProfileResponse;
}

const MobileMenu = ({
  isOpen,
  onDismiss,
  signedIn,
  subscriptions,
  profile,
}: Props) => {
  const logout = useLogoutUser();
  const router = useRouter();
  const queryClient = useQueryClient();

  useEffect(() => {
    const handleRouteChange = () => {
      onDismiss();
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);

  const onLogout = () => {
    logout.mutate(1, {
      onSuccess: async () => {
        router.push(LOGIN);
      },
    });
  };

  if (!isOpen) {
    return null;
  }

  const variant = HeaderVariant.dark;

  return (
    <Overlay>
      <Content>
        <CloseButton onClick={onDismiss}>
          <UnstyledButton>
            <Icon id={IconNames.x} color="var(--color-white)" />
          </UnstyledButton>
        </CloseButton>
        {signedIn && profile ? (
          <SignedInMobileMenu
            variant={variant}
            subscriptions={subscriptions}
            profile={profile}
            onLogout={onLogout}
          />
        ) : (
          <DefaultMobileMenu variant={variant} />
        )}
      </Content>
    </Overlay>
  );
};

const Overlay = styled(DialogOverlay)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  /* would love to remove this from here
  and in the header */
  z-index: 2;
`;

const Content = styled(DialogContent)`
  position: relative;
  width: 100%;
  height: 100%;
  background: hsl(0deg 0% 0% / 0.97);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
`;

const CloseButton = styled.div`
  position: absolute;
  top: -4px;
  right: 20px;
  /* padding here makes hit box larger */
  padding: 16px;

  @media ${(p) => p.theme.queries.phoneAndDown} {
    right: 0px;
    padding-right: 12px;
  }
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export default MobileMenu;
