import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import UnstyledButton from 'shared/UnstyledButton';
import Popover, { positionRight } from '@reach/popover';

type Props = {
  children: React.ReactNode | React.ReactNode[];
  content: React.ReactNode | React.ReactNode[];
};

/**
 * Popover component that is triggered by both hovering over the trigger and clicking on the trigger.
 * If triggered by hovering, the popover will close when the user mouses away from the trigger.
 * If triggered by clicking, the popover will close when the user clicks away from the trigger or clicks the trigger again.
 * @param children - trigger for popover
 * @param content - content be displayed in popover
 */
const NotificationsDialog = ({ children, content }: Props) => {
  const ref = useRef<HTMLButtonElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        popoverRef.current &&
        !popoverRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [ref]);

  return (
    <>
      <UnstyledButton ref={ref} onClick={() => setOpen(!open)}>
        {children}
      </UnstyledButton>

      {open ? (
        <Popover targetRef={ref} position={positionRight}>
          <Container
            ref={popoverRef}
            initial={{ opacity: 0.5 }}
            animate={{ opacity: 1 }}
          >
            <ContentWrapper>{content}</ContentWrapper>
          </Container>
        </Popover>
      ) : null}
    </>
  );
};

const Container = styled(motion.div)`
  padding: 8px;
  background-color: var(--color-gray-900);
  border-radius: 15px;
  max-width: 474px;
  margin-top: 20px;
  margin-right: -20px;
  overflow: hidden;

  &::after {
    content: '';
    width: 20px;
    height: 12px;
    position: absolute;
    top: 13px;
    right: 6px;
    clip-path: polygon(0% 100%, 50% 0%, 100% 100%);
    background-color: var(--color-gray-900);
  }
`;

const ContentWrapper = styled.div`
  margin-left: -8px;
  margin-right: -8px;
`;

export { NotificationsDialog };
