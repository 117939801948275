import { Notification } from '@solin-fitness/types';
import { useReadNotifications } from './notificationsQuery';
import { Media, MediaContextProvider } from 'media';
import {
  desktopNotificationHref,
  mobileNotificationHref,
} from './notificationsHelpers';
import { NotificationItemContent } from './NotificationItemContent';

// notifications will come in that have matching types and postIds / commentIds
// notifications should be sorted in descending order by createdAt

type NotificationProps = {
  notifications: Notification[];
};

const NotificationItem = ({ notifications }: NotificationProps) => {
  const userIds = notifications.map((notification) => notification.sender.id);
  const uniqueUserIds = [...new Set(userIds)];
  const mostRecent = notifications[0];

  const desktopHref = desktopNotificationHref[mostRecent.type](
    mostRecent,
    uniqueUserIds.length,
  );

  const mobileHref = mobileNotificationHref[mostRecent.type](
    mostRecent,
    uniqueUserIds.length,
  );

  const readNotifications = useReadNotifications();

  const handleNotificationClick = () => {
    const notificationIds = notifications.map(
      (notification) => notification.id,
    );
    readNotifications.mutate(notificationIds);
  };

  return (
    <MediaContextProvider>
      <Media greaterThanOrEqual="lg">
        <NotificationItemContent
          mostRecent={mostRecent}
          href={desktopHref}
          onClick={handleNotificationClick}
          count={uniqueUserIds.length}
        />
      </Media>
      <Media lessThan="lg">
        <NotificationItemContent
          mostRecent={mostRecent}
          href={mobileHref}
          onClick={handleNotificationClick}
          count={uniqueUserIds.length}
        />
      </Media>
    </MediaContextProvider>
  );
};

export { NotificationItem };
