import { useMutation, useQueryClient } from 'react-query';
import { logoutUser } from 'queries/auth';
import { notificationKeys } from '../components/Notifications/notificationsQuery';

const useLogoutUser = () => {
  const queryClient = useQueryClient();

  // writing the mutation like `useMutation(logoutUser, {})`
  // gives a TS error when trying to call mutate saying that the parameter
  // is not the same type as void.
  // so passing in _: number here as a placeholder to get around that error.
  return useMutation((_: number) => logoutUser(), {
    onSuccess: () => {
      queryClient.removeQueries('metadata');
      queryClient.removeQueries('profile');
      queryClient.removeQueries('streaks');
      queryClient.removeQueries(notificationKeys.all);
    },
  });
};

export default useLogoutUser;
