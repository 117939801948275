import { Notification, CommunityPostResponse } from '@solin-fitness/types';
import { groupBy, sort } from 'ramda';
import { getShortenedName } from '../../utils/helpers';

type NotificationType =
  | 'NEW_POST'
  | 'NEW_CREATOR_POST'
  | 'NEW_POST_COMMENT'
  | 'NEW_POST_LIKE'
  | 'NEW_COMMENT_LIKE'
  | 'COMMENT_ON_SAME_POST';

type PostId = number;

type NotificationGroup = `${NotificationType}-${PostId}`;

type NotificationsObject = Record<NotificationGroup, Notification[]>;

const groupNotifications = (notifications: Notification[]) => {
  // sort notification by group - TYPE and POST
  const notificationsObject: NotificationsObject = groupBy(
    (a: Notification) => {
      const grouping: NotificationGroup = `${a.type}-${a.postNotification.postId}`;
      return grouping;
    },
    notifications,
  );

  // sort notifications by created at
  const sortedNotificationObject: NotificationsObject = {};
  Object.entries(notificationsObject).forEach(([key, value]) => {
    sortedNotificationObject[key as NotificationGroup] =
      sortNotifications(value);
  });

  // split into separate notification arrays for each group
  const notificationArrays = Object.values(sortedNotificationObject);

  // sort the groups notification arrays to see which is most recent
  const sortedNotificationArrays = sort((a, b) => {
    const aDate = new Date(a[0].createdAt).getTime();
    const bDate = new Date(b[0].createdAt).getTime();
    return -1 * (aDate - bDate);
  }, notificationArrays);

  return sortedNotificationArrays;
};

// sort notification by createdAt
const sortNotifications = (notifications: Notification[]) => {
  return sort((a, b) => {
    const aDate = new Date(a.createdAt).getTime();
    const bDate = new Date(b.createdAt).getTime();
    return -1 * (aDate - bDate);
  }, notifications);
};

const getUsersWhoInteractedWithPost = (
  post: CommunityPostResponse,
  currentUserId: number,
) => {
  const postOwner = post.user;

  const commentedUsers = post.comments.map((comment) => comment.user);
  const likedUsers = post.likes.map((like) => like.user);

  const allUniqueUsers = [...commentedUsers, ...likedUsers];

  const userIds = allUniqueUsers.map((user) => user.id);
  const uniqueUsers = allUniqueUsers.filter(
    ({ id }, index) => !userIds.includes(id, index + 1),
  );

  const allUniqueUsersWithoutCurrentUser = uniqueUsers.filter(
    (u) => u.id !== currentUserId || u.id !== postOwner.id,
  );

  return allUniqueUsersWithoutCurrentUser;
};

const notificationName: Record<
  Notification['type'],
  (n: Notification) => [string, string]
> = {
  NEW_POST: (n) =>
    n.postNotification.type === 'page'
      ? [n.postNotification.pageName, '']
      : [n.postNotification.programName, ''],
  NEW_CREATOR_POST: (n) => notificationName.NEW_POST(n),
  COMMENT_ON_SAME_POST: (n) => {
    const name = getShortenedName({
      firstName: n.sender.profile.firstName,
      lastName: n.sender.profile.lastName,
    });
    return n.postNotification.type === 'page'
      ? [n.postNotification.pageName, name]
      : [n.postNotification.programName, name];
  },
  NEW_COMMENT_LIKE: (n) => notificationName.COMMENT_ON_SAME_POST(n),
  NEW_POST_COMMENT: (n) => notificationName.NEW_POST(n),
  NEW_POST_LIKE: (n) => notificationName.NEW_POST(n),
};

const notificationMessage: Record<
  Notification['type'],
  (location: string, name: string, prefix?: string) => string
> = {
  NEW_POST: (location, name, prefix = '') =>
    `${prefix} posted in the ${location} community`,
  NEW_CREATOR_POST: (location, name, prefix = '') =>
    `${prefix} posted in the ${location} community`,
  COMMENT_ON_SAME_POST: (location, name, prefix = '') =>
    `also commented on ${name}'s post in the ${location} community`,
  NEW_COMMENT_LIKE: (location, name, prefix = '') =>
    `${prefix} liked your comment on ${name}'s post in the ${location} community`,
  NEW_POST_COMMENT: (location, name, prefix = '') =>
    `${prefix} commented on your post in the ${location} community`,
  NEW_POST_LIKE: (location, name, prefix = '') =>
    `${prefix} liked your post in the ${location} community`,
};

const desktopNotificationHref: Record<
  Notification['type'],
  (notification: Notification, count?: number) => string
> = {
  NEW_POST: (n, count = 1) => {
    if (count > 1) {
      return n.postNotification.type === 'page'
        ? `/${n.postNotification.pageUrl}/community`
        : `/${n.postNotification.pageUrl}/program/${n.postNotification.programId}/community`;
    } else {
      return `/${n.postNotification.pageUrl}/notifications/${n.id}`;
    }
  },
  NEW_CREATOR_POST: (n, count = 1) =>
    desktopNotificationHref.NEW_POST(n, count),
  COMMENT_ON_SAME_POST: (n, count = 1) =>
    desktopNotificationHref.NEW_POST(n, 1),
  NEW_COMMENT_LIKE: (n, count = 1) =>
    desktopNotificationHref.COMMENT_ON_SAME_POST(n, count),
  NEW_POST_COMMENT: (n, count = 1) =>
    desktopNotificationHref.COMMENT_ON_SAME_POST(n, count),
  NEW_POST_LIKE: (n, count = 1) =>
    desktopNotificationHref.COMMENT_ON_SAME_POST(n, count),
};

const mobileNotificationHref: Record<
  Notification['type'],
  (notification: Notification, count?: number) => string
> = {
  NEW_POST: (n, count = 1) => {
    if (count > 1) {
      return n.postNotification.type === 'page'
        ? `/${n.postNotification.pageUrl}/community`
        : `/${n.postNotification.pageUrl}/program/${n.postNotification.programId}/community`;
    } else {
      return n.postNotification.type === 'page'
        ? `/${n.postNotification.pageUrl}/community/post/${n.postNotification.postId}`
        : `/${n.postNotification.pageUrl}/program/${n.postNotification.programId}/community/post/${n.postNotification.postId}`;
    }
  },
  NEW_CREATOR_POST: (n, count = 1) => mobileNotificationHref.NEW_POST(n, count),
  COMMENT_ON_SAME_POST: (n, count = 1) =>
    n.postNotification.type === 'page'
      ? `/${n.postNotification.pageUrl}/community/post/${n.postNotification.postId}`
      : `/${n.postNotification.pageUrl}/program/${n.postNotification.programId}/community/post/${n.postNotification.postId}`,
  NEW_COMMENT_LIKE: (n, count = 1) =>
    mobileNotificationHref.COMMENT_ON_SAME_POST(n, count),
  NEW_POST_COMMENT: (n, count = 1) =>
    mobileNotificationHref.COMMENT_ON_SAME_POST(n, count),
  NEW_POST_LIKE: (n, count = 1) =>
    mobileNotificationHref.COMMENT_ON_SAME_POST(n, count),
};

export {
  groupNotifications,
  getUsersWhoInteractedWithPost,
  notificationName,
  notificationMessage,
  desktopNotificationHref,
  mobileNotificationHref,
};
