import styled from 'styled-components';
import ProfileImage from '../ProfileImage';
import { Notification } from '@solin-fitness/types';
import Typography from 'shared/Typography';
import { ReactNode } from 'react';
import { TypeVariant } from '../../shared/Typography/Typography';
import { getTimeSince } from '../../utils/date-fns';
import { getShortenedName } from '../../utils/helpers';
import Link from 'next/link';
import { notificationMessage, notificationName } from './notificationsHelpers';

const NotificationItemContent = ({
  mostRecent,
  href,
  count,
  onClick,
}: {
  mostRecent: Notification;
  href: string;
  count: number;
  onClick: () => void;
}) => {
  const [location, name] = notificationName[mostRecent.type](mostRecent);
  const message = notificationMessage[mostRecent.type](
    location,
    name,
    count > 1 ? 'also' : '',
  );

  const createdAt = new Date(mostRecent.createdAt);

  return (
    <Link href={href} passHref>
      <Wrapper onClick={onClick}>
        <ProfileImage
          imageSize={{
            width: 40,
            height: 40,
          }}
          firstName={mostRecent.sender.profile.firstName}
          lastName={mostRecent.sender.profile.lastName}
          profilePicture={mostRecent.sender.profile.profilePictureUrlPublicId}
          userId={mostRecent.sender.id}
          noBorder
        />
        <TextWrapper>
          <Text color="var(--color)">
            <Description notification={mostRecent} count={count} />
            {message}
            <DateSpan>{` ${getTimeSince(createdAt)}`}</DateSpan>
          </Text>
        </TextWrapper>
        {!mostRecent.read ? <UnreadDot /> : null}
      </Wrapper>
    </Link>
  );
};

export { NotificationItemContent };

const Description = ({
  notification,
  count,
}: {
  notification: Notification;
  count: number;
}) => {
  const shortenedName = getShortenedName({
    firstName: notification.sender.profile.firstName,
    lastName: notification.sender.profile.lastName,
  });

  if (count === 1) {
    return <BoldTitle>{`${shortenedName} `}</BoldTitle>;
  }

  const others = count === 2 ? 'other' : 'others';
  return (
    <>
      <BoldTitle>{shortenedName}</BoldTitle>
      {` and `}
      <Bold>{`${count - 1} ${others} `}</Bold>
    </>
  );
};

const Wrapper = styled.a`
  text-decoration: none;
  display: flex;
  padding: 8px 20px;
  gap: 16px;
  /* width: min(474px, 100%); */
  background: var(--color-gray-900);
  transition: background 0.2s ease-in-out;
  will-change: background;

  &:hover {
    background: var(--color-gray-700);
  }

  --avatar-size: 48;
  @media ${(p) => p.theme.queries.tabletAndDown} {
    --avatar-size: 40;
    background: var(--color-off-white-500);

    &:hover {
      background: var(--color-off-white-900);
    }
  }
`;

const TextWrapper = styled.div`
  padding: 5px 0px;
  color: var(--color-white);

  @media ${(p) => p.theme.queries.tabletAndDown} {
    color: var(--color-black-500);
  }
`;

const Bold = styled.span`
  font-weight: var(--font-weight-semibold);
`;

const BoldTitle = styled(Bold)`
  text-transform: capitalize;
`;

const DateSpan = styled.span`
  color: var(--color-primary-500);
`;

const Text = ({
  children,
  color,
}: {
  children: ReactNode | ReactNode[];
  color: string;
}) => (
  <Typography
    as="p"
    variant={TypeVariant.body01}
    tablet={TypeVariant.body02}
    color={color}
  >
    {children}
  </Typography>
);

const UnreadDot = styled.div`
  margin-left: auto;
  align-self: center;
  width: 12px;
  height: 12px;
  border-radius: 999px;
  flex-shrink: 0;
  background: var(--color-primary-300);
`;
