import { fetchUserMetadata } from '../queries/user';
import { useQuery } from 'react-query';

/**
 * cacheTime set to 15 minutes
 * since metadata is used on almost all pages, i think this
 * is a good move. cacheTime will also get invalidated whenever
 * the query gets invalidates through query client so will always
 * stay up to date
 */
const useUserMetadata = (enabled = false) => {
  // only want to allow query to be active when user is logged in
  return useQuery('metadata', fetchUserMetadata, {
    cacheTime: 15 * 60 * 1000,
    enabled,
  });
};

export default useUserMetadata;
